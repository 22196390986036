@use '../utils/variables';

.service-type {
  display: flex;
  gap: 0.6rem;
  width: 40rem;

  &-item {
    padding: 0.75rem;
    height: 4.75rem;
    background-color: variables.$white;
    display: flex;
    justify-content: space-between;
    gap: 0.6rem;
    border: 1px solid variables.$dark-4;
    flex: 1;

    &__icon {
      display: flex;
      align-items: center;
      &__suffix {
        justify-self: flex-end;
      }
    }
    &__infos {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 80%;
      &__name {
        color: variables.$main-text-color;
        font-size: 15px;
      }
      &__description {
        color: variables.$dark-2;
      }
    }
  }
}

.service-type-item:hover {
  background-color: variables.$light-2;
  cursor: pointer;
}

.service-type-item--selected {
  border: 1px solid variables.$main-bg-color;
}

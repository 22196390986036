@use '../utils/variables';

.account-menu {
  z-index: 1;
  position: relative;
  &__box {
    right: 0;
    margin-top: 0.5rem;
    position: absolute;
    min-width: 300px;
    padding: 1.8rem 1rem;
    background-color: variables.$main-bg-color;
    color: variables.$white;
  }
  &__title {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  &__logout {
    border: none !important;
    color: variables.$white !important;
    background-color: variables.$main-bg-color;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    &__item {
      gap: 0.5rem;
    }
  }
  &__triangle {
    position: absolute;
    top: -8px;
    right: 0.8rem;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid variables.$main-bg-color;
  }
}

@use '../utils/variables';

.passenger-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #001c40;
}

.input-passenger-name {
  margin-bottom: 1.5vh;
}

.input-passenger-name-background {
  background-color: #fff;
  border: 1px solid #C7C9D9;
  width: 40rem;
  padding: 1rem;
}

.input-passenger-details {
  gap: 0.7rem;
  width: 65rem;
  display: flex;
  &__field {
    align-self: center;
  }
}

.input-passenger-details-no-sidebar {
  display: flex;
  justify-content: space-between;
  
  .text-passenger-name {
    width: 29rem!important;
  }
}

.text-passenger-name {
  height: 3.438rem;
}

.input-passenger:focus {
  border: solid;
  border-color: black;
  border-width: 1.5px;
  outline-style: none;
}

.form-estimate {
  margin-bottom: 10rem;
}

.passenger {
  &__dropdown {
    z-index: 2;
    background-color: variables.$white;
    border: 1px solid #dde5e9;
    border-top: 1px solid #fff;
    max-height: 12rem;
    overflow-y: auto;
  }

  &__prefix {
    margin-right: 1rem;
  }

  &__item {
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: #f2f4f7;
    }
  }

  &__input {
    width: 100% !important;
  }
}

.passenger__item > .passenger-item {
  padding: 0 !important;
}

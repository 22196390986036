@use '../../utils/variables';

.picker {
  position: relative;
  width: fit-content;

  &__placeholder {
    position: absolute;
    background-color: variables.$white;
    top: 30%;
    left: 10%;

    &--text {
      text-transform: uppercase;
      color: variables.$dark-2;
      font-family:
        'EuclidCircularA-Semibold',
        -apple-system,
        BlinkMacSystemFont,
        sans-serif;
    }
  }

  &__input {
    width: 11rem;
    height: 3.438rem;

    &--white {
      color: variables.$white;
    }
  }

  &__image {
    background-color: variables.$white;
    position: absolute;
    right: 10%;
    top: 35%;
    pointer-events: none;
  }
}

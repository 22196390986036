.mission-array-detail-container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;

  button {
    margin-top: 1rem;
  }

  .attachment-item {
    margin-top: .25rem;
  }
}
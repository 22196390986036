@use '../utils/variables';
@use '../base/typography';

.mission-row {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  transition:
    margin-bottom 0.16s,
    box-shadow 0.42s,
    gap 0.42s;

  &--empty {
    padding-top: 2.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 10rem;
      height: 10rem;
    }

    &--title,
    &--subtitle {
      color: variables.$main-text-color;
    }

    &--title {
      margin-top: 1.875rem;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 1.625rem;
    }

    &--subtitle {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.488rem;
    }
  }

  &--opened {
    @extend .mission-row;
    margin-bottom: 0.5rem;
    gap: 1px;
  }

  &__info {
    padding: 0 0.62rem 0 0.62rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 5rem;
    border-radius: 4px;
    @extend .lead-paragraph;
    font-size: 14px;
    font-weight: 400;

    &--opened {
      @extend .mission-row__info;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    }

    &__account,
    &__ride {
      display: flex;
      width: 4rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;

      span {
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 4rem;
      }
    }

    &__date {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      width: 4.5rem;
    }

    &__time {
      display: flex;
      align-items: center;
      width: 6rem;

      justify-content: space-evenly;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__passenger {
      width: 12rem;
      margin-left: -1rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__trip {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      width: 16rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__service {
      width: 5rem;
      align-content: center;
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__optionnalRequest {
      display: flex;
      align-items: center;
    }

    &__vehicle {
      display: flex;
      flex-direction: column;
      width: 5rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__type {
      display: flex;
      flex-direction: column;
      width: 5rem;
    }

    &__driver {
      width: 7rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__price {
      width: 3rem;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__actions {
      display: flex;
      gap: 1rem;
      width: 3.5rem;
      justify-content: flex-end;
    }

    &_actions-button {
      cursor: pointer;
    }

    &__status-circle {
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
    }

    // STYLE INFO SUPP

    &__more-info {
      border-radius: 4px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }
}

.text-color-blue-4 {
  color: variables.$blue-4;
}

.text-color-dark {
  color: variables.$dark-1;
}

.text-color-dark2 {
  color: variables.$dark-2;
}

.text-color-blue {
  color: variables.$main-text-color;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-13 {
  font-size: 13px;
}

// STYLE PASTILLE STATUS

.status {
  width: 4vw;
  margin: 5px;
}

.phone {
  display: flex;
  gap: 0.2rem;
}

.time-img {
  margin-bottom: 0.1rem;
}

.RideStarted {
  background-color: variables.$status-green;
}

.QuotationInProgress {
  background-color: variables.$status-orange;
}

.QuotationSent {
  background-color: variables.$status-yellow;
}

.PassengerDropedOn {
  background-color: variables.$main-bg-color;
}

.Closed {
  background-color: variables.$status-blue;
}

.Billed {
  background-color: variables.$status-purple;
}

.PrendingProcessing {
  background-color: variables.$status-red;
}

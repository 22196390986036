@use '../utils/variables';

.transport-form,
.transport-view,
.transport-details-form {
  display: flex;
  height: 100%;
  align-items: center;
  flex: 1;
  gap: 0.5rem;
  white-space: nowrap;
}

.transport-form {
  .input-text-transport-reference {
    width: 15rem;
    &__train {
      width: 15rem;
    }
    &__info {
      width: 15rem;
    }
  }
}

.transport-view {
  font-size: 14px;
  font-weight: 400;
  gap: 0.875rem;

  .reference {
    margin-left: 1rem;
    display: flex;
    gap: 0.5rem;
    width: 5rem;
  }

  .informations {
    display: flex;
  }

  .terminal {
    display: flex;
  }

  .hour {
    display: flex;
    gap: 0.25rem;
  }
}

.transport-icon {
  filter: invert(47%) sepia(10%) saturate(818%) hue-rotate(181deg) brightness(92%) contrast(81%);
}

.transport-toogle {
  .MuiToggleButtonGroup-root {
    button {
      border-color: variables.$light-1;
      background-color: variables.$white;
      height: 3.438rem;
      img {
        filter: invert(49%) sepia(10%) saturate(701%) hue-rotate(181deg) brightness(90%)
          contrast(88%);
      }

      &.Mui-selected {
        background-color: variables.$secondary-bg-color;
        img {
          filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(305deg) brightness(96%)
            contrast(104%);
        }
      }
    }
  }
}

.transport-details-form {
  height: 3.438rem;
  background-color: variables.$white;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  font-size: 14px;
  font-weight: 500;
  gap: 0.875rem;
  text-transform: uppercase;

  .title {
    color: variables.$dark-2;
  }

  .value {
    color: variables.$main-text-color;
  }

  .hour {
    display: flex;
    gap: 0.25rem;
  }

  &__date,
  &__error {
    height: 3.438rem;
    background-color: variables.$white;
    padding: 0.938rem;
    font-size: 12px;
    font-weight: 500;
    gap: 0.5rem;
    word-wrap: break-word;
    display: flex;
    align-items: center;
  }

  &__date {
    color: variables.$main-text-color;
  }

  &__error {
    color: variables.$error-color;
  }

  &__train {
    display: flex;
    gap: 0.5rem;
  }
}

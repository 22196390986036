@use '../utils/variables';

.header-chabe {
  margin: 0;
  height: 4.9rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 2rem;
  justify-content: space-around;
}

.header-item {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.header-home {
  margin-right: auto;
}

.header-item li {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.baseline {
  text-transform: uppercase;
  font-size: 16px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  font-weight: 500;
  line-height: 100%;
  color: variables.$main-text-color;
}

@use '../utils/variables';

.dashboard-page {
  background-color: variables.$light-grey;
  display: flex;
  flex-direction: column;
}

h2 {
  text-transform: uppercase;
}

.booking-block {
  display: flex;
}

@use '../utils/variables';

.sidebar {
  width: 50%;
  min-width: 400px;
  height: 100%;
  background-color: variables.$white;
  position: fixed;
  top: 0;
  right: 0;
  transition: right 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  z-index: 3;
  &__bg {
    top: 0;
    left: 0;
  }
  padding-left: 1.5rem;
}

.sidebar.open {
  right: 0;
}

.sidebar-header {
  padding: 10px;
  text-align: right;
}

.sidebar-custom-content {
  flex-grow: 1;
  padding: 20px;
  max-height: calc(100vh - 2.75rem - 5.25rem);
  overflow-y: auto;
}

.sidebar-footer {
  width: 100%;
  padding: 10px;
  text-align: center;
  align-self: flex-end;
  button {
    margin: 0 10px;
  }
}

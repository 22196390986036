// Colors
$main-bg-color: #001c40;
$lighter-bg-color: #385273;
$light-grey: #f5f5f5;
$grey: #062e37;
$white: #fff;
$black: #000;
$main-text-color: #001c40;
$lighter-text-color: #385273;
$secondary-text-color: #fff;
$secondary-bg-color: #c5003e;
$error-color: #f65151;
$error-alternative: #ff5c5c;
$error-light: #ff8080;
$warning-color: #ffcc00;
$warning-alternative: #fddd48;
$warning-light: #fded72;
$info-color: #0063f7;
$info-alternative: #5b8def;
$info-light: #9dbff9;
$success-color: #06c270;
$success-alternative: #39d98a;
$success-light: #57eba1;
$dark-1: #3a3a3c;
$dark-2: #6b7588;
$dark-3: #8f90a6;
$dark-4: #c7c9d9;
$light-1: #dde5e9;
$light-2: #ebebf0;
$light-3: #f2f2f5;
$light-4: #fafafc;
$blue-2:#E3EDFF;
$blue-4: #25406a;
$status-green: #06c270;
$status-orange: #e56000;
$status-yellow: #ffcc00;
$status-blue: #3568d4;
$status-purple: #60329b;
$status-red: #c5003e;

@use '../utils/variables';

.panel {
  left: 80px;
  background-color: variables.$main-bg-color;
  color: variables.$light-grey;
  width: 31.25rem;
  height: 100%;
  position: absolute;
  font-size: 1rem;

  .panel-container {
    width: 80%;
    margin: auto;
    display: block;

    img {
      padding-top: 7.5rem;
      padding-bottom: 7.5rem;
      margin: auto;
      display: block;
    }

    h1 {
      color: variables.$light-grey;
      font-size: 1rem;
    }

    p {
      margin-top: 1.25rem;
      color: variables.$light-grey;
    }
  }
}

.background_branding_container {
  background: variables.$main-bg-color;
  height: 100%;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  width: 100%;
  z-index: 0;
  position: fixed;
  left: 0;
  overflow: hidden;
  top: 0;

  .background_background_image {
    width: 100% !important;
    min-height: 100% !important;
  }
}
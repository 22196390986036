.mission-form {
  min-width: 20rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__divider {
    border-top: 1px solid #fff;
    margin: 13px 0 13px 0;
  }
}

@use '../utils/variables';

.navbar-chabe {
  width: 3.5rem;
  display: inline-flex;
  padding: 1.5rem 0.75rem;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: variables.$main-bg-color;
}

.navbar-logo {
  margin-top: auto;
}

menu {
  padding: 0;
}

menu li {
  margin-bottom: 1.5rem;
}

.nav-link {
  padding: 0.5rem !important;
}

.is-active {
  border-radius: 7px;
  background-color: variables.$lighter-bg-color;
}

@use '../utils/variables';

.time {
  width: 40rem;
  &__input {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 0.2rem;
    label {
      text-transform: uppercase;
      color: variables.$dark-2;
      font-size: 14px;
      font-family:
        'EuclidCircularA-Semibold',
        -apple-system,
        BlinkMacSystemFont,
        sans-serif;
      line-height: 1.7;
    }
    &__duration {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      &__number {
        width: 6rem;
        height: 3.438rem;
      }
    }
    &__help {
      color: variables.$dark-2;
      &__icon {
        margin-right: 0.2rem;
      }
    }
  }
}

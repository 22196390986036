@use '../utils/variables';

.passenger-item {
  padding: 0.9rem 1.25rem;
  display: flex;
  gap: 0.9rem;

  &__active {
    border-left: 0.188rem solid variables.$status-blue;
    padding: 0.9rem 1.062rem;
  }

  &__infos {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;

    &__name {
      text-transform: uppercase;
      color: variables.$dark-2;
    }

    &__email {
      color: variables.$lighter-bg-color;
    }
  }
}

.item-bg-dark {
  background-color: variables.$light-3;
}

@use '../utils/variables';

#languageSelector {
  background-color: lime;
}

#lang-selector {
  border-style: none;
  color: variables.$main-text-color;
}

#lang-selector:focus {
  outline: none;
}

#lang-selector option:checked {
  color: white;
  background-color: variables.$main-bg-color;
}

#lang-selector option:hover {
  background-color: lime;
}

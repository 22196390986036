@use '../../utils/variables';

input {
  padding: 1rem !important;
  border: 1px solid variables.$light-1 !important;
  background-color: variables.$white;
  color: variables.$dark-2;
  font-weight: 500;
  letter-spacing: 1px;

  &::placeholder {
    color: variables.$dark-2 !important;
  }
}

@use '../utils/variables';

.cancel-buttons {
  margin: 0 4rem 2rem 4rem;

  &__divider {
    border-color: variables.$light-grey;
  }

  &__container {
    display: flex;
    gap: 1rem;
  }

  &__buttons {
    margin: 2rem;
  }

  &__cancel,
  &__confirm,
  &__refuse {
    height: 2.5rem;
    width: 100%;
  }

  &__refuse {
    background-color: variables.$secondary-bg-color;
  }
}

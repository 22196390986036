@use '../utils/variables';

.notifications {
  position: relative;
  &__icon {
    cursor: pointer;
    &__counter {
      position: absolute;
      bottom: -0.3rem;
      right: -0.5rem;
      width: 1.1rem;
      height: 1.1rem;
      border-radius: 50%;
      background-color: variables.$status-red;
      color: variables.$white;
      font-size: 0.62rem;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      &__secondary {
        top: 2.3rem;
        left: 1.7rem;
      }
    }
  }
  &__content {
    z-index: 2;
    right: -3.875rem;
    margin-top: 0.5rem;
    position: absolute;
    width: 18.25rem;
    min-height: 17.625rem;
    max-height: 80vh;
    padding: 1rem;
    background-color: variables.$white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    &__header {
      display: flex;
      gap: 0.5rem;
      color: variables.$main-text-color;
    }
    &__body {
      overflow-y: auto;
      &__item {
        margin-bottom: 0.5rem;
        &__content {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          &__trip {
            &__steps {
              display: flex;
              gap: 0.375rem;
            }
            &__place {
              display: flex;
              gap: 0.375rem;
            }
          }
          &__date {
            display: flex;
            &__item {
              display: flex;
              gap: 0.375rem;
              margin-right: 2rem;
            }
          }
          &__passenger {
            display: flex;
            gap: 0.375rem;
          }
          &__passengers {
            &__item {
              display: flex;
            }
          }
        }
        &__divider {
          border-top: 1px solid variables.$dark-4;
          margin: 13px 0 13px 0;
        }
      }
      &__empty {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: variables.$main-text-color;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.625rem;
      }
    }
  }
  &__triangle {
    position: absolute;
    top: -8px;
    right: 4rem;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid variables.$white;
  }
}

@use '../utils/variables';

.cancel {
  width: 41rem;
  position: relative;
  flex: 1;
  color: variables.$main-text-color;

  &__title {
    font-weight: 700;
    text-transform: inherit;
    margin: 2rem;
  }

  &__info {
    padding: 0.5rem 0;
    min-height: 18rem;
    margin: 0.2rem 2rem;
    hr {
      border-color: variables.$main-bg-color;
    }
  }

  &__info-title {
    font-weight: bold;
  }

  &__info-estimate {
    display: flex;
  }

  &__info-divider {
    border-color: variables.$main-bg-color;
  }

  &__info-estimate-label {
    width: 11rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__info-estimate-passenger {
    margin-left: 2rem;
  }

  &__divider {
    border-color: variables.$main-bg-color;
  }
}

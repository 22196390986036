@use '../utils/variables';

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: absolute;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #001c4085;
  padding-left: 3.5rem;

  &__img {
    width: 3%;
    position: absolute;
  }

  &__svg {
    animation: rotate 7s linear infinite;
    height: 20vh;
  }
}

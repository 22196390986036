@use '../utils/variables';

.passenger-search {
  &__results {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(100vh - 4.9rem - 3rem - 4rem - 3.438rem - 7.5rem);
  }

  .passenger-item {
    &:hover {
      background-color: variables.$light-1;
    }
  }
}

@use '../utils/variables';

.passenger-page {
  height: 98%;

  .button-create-passenger {
    width: 20.438rem;
  }
  .passenger-page-content {
    display: flex;
    flex-direction: row;

    .search {
      flex: 1;
      background-color: variables.$white;
      margin-right: 3rem;
      height: 76vh;
    }

    .form-container {
      flex: 2;
      flex-direction: column;
      display: flex;

      .passenger-title {
        flex: 1;
      }

      .passenger-form {
        width: 100%;
        background-color: variables.$white;
        flex: 1;
        padding: 1.875rem;
      }
      height: 76vh;
      overflow-y: auto;
    }

    .logo-before-form {
      flex: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1.875rem;
    }
  }

  h2 {
    text-transform: uppercase;
  }

  .form-new-passenger {
    background-color: white;
    width: 85rem;
    padding: 2rem;

    &__divider {
      border-top: 1px solid variables.$main-bg-color;
    }
  }

  .passenger-body {
    margin-top: 2rem;
  }

  .body-form-page {
    margin-top: 2rem;
  }
}

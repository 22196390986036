@use '../../utils/variables';

.input-password {
  width: fit-content;
  display: flex;
  position: relative;

  &__input {
    width: 24rem;
    background-color: transparent;
    border: 2px solid variables.$white;
    border-left: 0;
    color: variables.$white;
    text-indent: 2rem;

    &::placeholder {
      color: variables.$white;
      text-indent: 2rem;
    }
  }

  &__prefix {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 0px;
    width: 38px;
    position: absolute;
    top: 30%;
    left: 3%;
  }

  &__cross {
    cursor: pointer;
    right: 4%;
    top: 30%;
    color: variables.$white;
    position: absolute;
  }
}

@use '../utils/variables';

.confirmation {
  width: 41rem;
  position: relative;
  flex: 1;
  color: variables.$main-text-color;

  &__title {
    font-weight: 700;
    text-transform: inherit;
    margin: 2rem;
  }

  &__info {
    padding: 0.5rem 0;
    min-height: 18rem;
    margin: 0.2rem 2rem;
    hr {
      border-color: variables.$main-bg-color;
    }
  }

  &__divider {
    border-color: variables.$main-bg-color;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
  }

  &__buttons-cancel,
  &__buttons-confirm {
    height: 2.5rem;
  }

  &__buttons-confirm {
    max-width: 217px;
  }

  &__buttons-cancel {
    max-width: 132px;
  }
}

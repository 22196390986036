@use '../utils/variables';
@use '../base/typography';

$search-field-width: 15rem;
$number-of-header-items: 5;
$padding-value: 1rem;
$tab-item-width: 15rem;

.mission-array {
  background: variables.$white;
  box-shadow: 4px 28px 24px 0 rgba(225, 225, 232, 0.5);
  display: flex;
  flex: 1;
  flex-direction: column;

  .pagination {
    margin-top: 1rem;
    justify-content: center;
  }
}

.mission-array__header {
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mission-array__header__tab {
  display: flex;
}

.mission-array__table {
  padding: 1rem 1.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
}

.mission-array__table__header {
  height: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0.62rem 1rem 0.62rem;
}

.mission-array__table__body {
  display: flex;
  flex-direction: column;
}

.mission-array__table__body > div:nth-child(odd) > div {
  background: variables.$light-grey;
}

.mission-array__table__body > div:nth-child(even) > div {
  background: variables.$white;
}

.search-field {
  width: $search-field-width;
  height: 2.5rem;
  border: 1px solid #e1e1e8;
  border-radius: 0.5rem;
  padding: 0 1rem;
  font-size: 1.4rem;
  color: #a1a1a1;
}

.line {
  transition: transform 0.3s;
  height: 1px;
  width: 100%;
  background-color: variables.$light-1;
}

.tab {
  cursor: pointer;
  width: $tab-item-width;
  display: flex;
  justify-content: center;
  align-items: center;
  color: variables.$main-text-color;
  overflow: hidden;
  white-space: nowrap;
  @extend .body-normal;
}

.tab.active {
  font-weight: bold;
}

.tab__title {
  flex-grow: 1;
  text-align: center;
}

.reload {
  margin-right: 0.5rem;
  visibility: hidden;
}

.reload.active {
  visibility: visible;
}

.line__tab {
  height: 2px;
  width: $tab-item-width;
  background-color: variables.$main-bg-color;
  transition: transform 0.3s;

  &--1 {
    @extend .line__tab;
    transform: translateX(calc(0%));
  }

  &--2 {
    @extend .line__tab;
    transform: translateX(calc(100%));
  }

  &--3 {
    @extend .line__tab;
    transform: translateX(calc(200%));
  }

  &--4 {
    @extend .line__tab;
    transform: translateX(calc(300%));
  }

  &--5 {
    @extend .line__tab;
    transform: translateX(calc(400%));
  }
}

.mission-array__table__header-element {
  display: flex;
  align-items: center;
  gap: 0.6rem;
  padding: 0.313rem;

  &--account {
    @extend .mission-array__table__header-element;
    width: 4rem;
  }

  &--ride {
    @extend .mission-array__table__header-element;
    width: 4rem;
  }

  &--date {
    @extend .mission-array__table__header-element;
    width: 4.5rem;
  }

  &--time {
    @extend .mission-array__table__header-element;
    width: 6rem;
  }

  &--passenger {
    @extend .mission-array__table__header-element;
    width: 12rem;
  }

  &--trip {
    @extend .mission-array__table__header-element;
    width: 16rem;
  }

  &--service {
    @extend .mission-array__table__header-element;
    width: 5rem;
  }

  &--vehicle {
    @extend .mission-array__table__header-element;
    width: 5rem;
  }

  &--driver {
    @extend .mission-array__table__header-element;
    width: 7rem;
  }
  &--type {
    @extend .mission-array__table__header-element;
    width: 5rem;
  }

  &--price {
    @extend .mission-array__table__header-element;
    width: 3rem;
  }

  &--actions {
    @extend .mission-array__table__header-element;
    width: 3.5rem;
  }

  &--status {
    @extend .mission-array__table__header-element;
    width: 0.8rem;
  }

  &--empty-space {
    @extend .mission-array__table__header-element;
    width: 21px;
  }
}

.mission-array__table__header-element > div {
  cursor: pointer;
}

.header-arrow {
  width: 0.5rem;
  height: 1.5rem;
  background-image: url('../../assets/img/icon/arrow-up-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  &__asc {
    background-image: url('../../assets/img/icon/stateUp.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 0.5rem;
    height: 1.5rem;
  }
  &__desc {
    background-image: url('../../assets/img/icon/stateDown.svg');
    background-repeat: no-repeat;
    background-position: center;
    width: 0.5rem;
    height: 1.5rem;
  }
}

.textarea {
  width: 100%;
  min-width: 250px;
  text-transform: inherit;
  font-weight: inherit;
  font-family:
    'EuclidCircularA-Regular',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  font-size: 14px;
}

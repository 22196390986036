@use '../utils/variables';

.mission-view {
  flex: 1;
  color: variables.$main-text-color;

  &__info {
    margin: auto;
    display: flex;
    width: 100%;

    &-label {
      width: 10rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    &-value {
      flex: 1;
    }
  }
}

@use '../utils/variables';
@use '../base/typography';

.mission-row-details {
  &__main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1rem;
    justify-content: space-between;

    &__external_reference_passenger {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    &__external_reference {
      display: flex;
      flex: 1;
      gap: 0.6rem;
    }

    &__passenger {
      display: flex;
      align-items: center;
      width: 3.5rem;
    }

    &__addresses {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
      flex: 4;
    }

    &__buttons {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    &__status-button {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      background-color: white;
      border: none;
      border-radius: 32px;
      color: black;
      font-size: 16px;
      padding: 0 0.6rem 0 0.8rem;
      cursor: pointer;

      &--not-clickable {
        @extend .mission-row-details__main__status-button;
        cursor: unset;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.6rem;
  }

  &__title {
    color: variables.$dark-1;
    white-space: nowrap;
  }

  &__value {
    color: variables.$lighter-bg-color;
    word-break: break-all;
  }

  &__reference {
    color: variables.$dark-1;
  }

  &__value,
  &__title {
    font-family:
      'EuclidCircularA-ligth',
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
    font-size: 0.875rem;
  }
}

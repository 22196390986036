@use '../utils/variables';

.footer-chabe {
  background-color: variables.$light-grey;
  margin: 0;
  height: 3rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 1rem 2rem;
  justify-content: space-around;

  &__item {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    &__rights {
      display: flex;
      align-items: center;
      justify-content: center;
      &__img {
        width: 1rem;
        height: 1rem;
      }
      &__text {
        margin-left: 0.5rem;
        margin-bottom: 0 !important;
      }
    }
    &__legal {
      &__text {
        margin-right: 2.5rem;
        margin-bottom: 0 !important;
        color: variables.$main-text-color;
      }
      text-decoration: none;
    }
    &__legal:hover {
      text-decoration: none !important;
    }
  }
}

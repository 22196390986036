@use '../utils/variables';
@import '~react-toastify/scss/variables';

.inner-toasted {

  @keyframes #{$rt-namespace}__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .#{$rt-namespace}__progress-bar {
    animation: #{$rt-namespace}__trackProgress linear 1 forwards;
  }

  font-size: 12px;
  margin: 1rem 0;
  max-width: 40rem;

  &__container {
    position: inherit;
    white-space: pre-wrap;
  }

  .Toastify__toast-body {
    display: flex;
    align-items: center;
    background-color: variables.$white;
    padding: 1rem;
    gap: 0.5rem;
    font-family:
      'EuclidCircularA-Semibold',
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
    font-weight: 500;
    color: variables.$main-text-color;
  
    ol,
    ul {
      li {
        color: #385273;
        font-size: 12px;
        font-weight: 500;
        word-wrap: break-word;
      }
  
      li.error {
        list-style-image: url('../../assets/img/icon/error.svg');
      }
    }
  
    .title {
      color: #001c40;
      font-size: 12px;
      font-weight: 500;
      word-wrap: break-word;
      display: flex;
      gap: 0.5rem;
    }
  }
  
  .Toastify__toast {
    width: 100%;
  
    &-container {
      box-shadow: 1px 1px 13px variables.$dark-2;
  
      &--error {
        color: variables.$error-color;
      }
  
      &--success {
        color: variables.$success-color;
      }
    }
  }
}

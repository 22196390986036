@use '../utils/variables';
@use '../base/typography';

.search-filter:nth-child(1) {
  height: 2rem !important;
  width: 18rem;
}
.search-filter:nth-child(1):focus {
  outline: none;
}

#searchField > .input-text > .input-text__cross {
  display: none;
}

#searchField {
  height: 2rem;
  margin-right: 1rem;
}

.search-filter {
  &__press-enter {
    display: flex;
    justify-content: space-between;
    width: 18rem;
    position: relative;
    top: -0.1rem;
    background-color: white;
    border: 1px solid #dde5e9;
    border-top: 0;
    color: variables.$dark-2;
    padding: 0.3rem 0.8rem;
    cursor: pointer;
  }
}

@use '../utils/variables';

.sign-in {
  width: 545px;
  height: 100%;
  background-color: variables.$main-bg-color;
  margin-left: 80px;
  position: relative;
}

.chabe-logo {
  width: 188px;
  height: 42px;
  margin-top: 117px;
  margin-left: auto;
  margin-right: auto;
}

.chabe-baseline {
  font-size: 16px;
  color: variables.$white;
  margin: 40px 0px;
}

.login-title {
  font-size: 36px;
  color: variables.$white;
  margin-bottom: 40px;
}

.welcome-box {
  width: 785px;
}

.welcome-text {
  width: 578px;
  margin: 0px auto;
}

.welcome-text-title {
  font-size: 48px;
  text-align: end;
}

.welcome-text-content {
  font-size: 24px;
  text-align: end;
}

.logo-chabe2 {
  width: 62px;
  text-align: right;
}

.login {
  display: flex;
  justify-content: space-between;
  background-image: url('../../../public/images/login-img.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}

.login__email {
  background-color: transparent !important;
  color: variables.$white !important;
  font-size: 14px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  text-transform: inherit;

  &::placeholder {
    text-transform: uppercase;
    color: variables.$white !important;
    font-size: 14px;
    font-family:
      'EuclidCircularA-Semibold',
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
  }
}

.login__password {
  background-color: transparent !important;
  color: variables.$white !important;
  text-transform: inherit;

  &::placeholder {
    text-transform: uppercase;
    color: variables.$white !important;
    font-size: 14px;
    font-family:
      'EuclidCircularA-Semibold',
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
  }
}

.login__submit {
  width: 24rem;
}

@use '../utils/variables';

.trip-component {
  display: flex;
  flex-direction: row;

  .trip-container-title-fields {
    width: 40rem;

    .trip-container-title {
      display: flex;
      flex-direction: row;

      .trip-component-add-step-button {
        display: flex;
        padding: 0;
        border: none;
        background: none;
        margin-bottom: 8px;
        margin-left: auto;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;

        .trip-component-add-step-icon {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.trip {
  gap: 0.5rem;
  width: 100%;
  position: relative;
}

.trip-container {
  gap: 1rem;

  &__divider {
    height: 29px;
    width: 1px;
    position: absolute;
    left: 1.75rem;
    z-index: 1;
    background-color: variables.$info-light;

    @for $i from 0 through 36 {
      &__#{$i} {
        @extend .trip-container__divider;
        top: calc(2.813rem + $i * (3.438rem + 0.5rem));
      }
    }
  }
}

.trip-container-inverse {
  padding-top: 2.9rem;
  padding-left: 0.5rem;
  .pointer {
    margin-top: calc(3.438rem + 0.5rem - 2.438rem);
    cursor: pointer;
  }
}

.trip-container-transport {
  padding-left: 0.5rem;

  .trip-container-transport-step {
    height: 3.438rem;
  }
  .pointer {
    margin-top: calc(3.438rem + 0.5rem - 2.438rem);
    cursor: pointer;
  }
}

.trip_input {
  width: 100% !important;
}

.trip-arrow-right {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.trip-step-column {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .value,
  .transport {
    display: flex;
    gap: 0.5rem;
    font-size: 0.875rem;
    flex: 1;
  }
}

.trip-step-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 11vw;
}

.trip-step-column-light__departure-arrival {
  display: flex;
  flex-direction: column;
  gap: 0.16rem;
  justify-content: center;
  max-width: 60%;
}

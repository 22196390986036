.new-request {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.nr-elements {
  display: flex;
  flex-direction: row;
}

@use '../utils/variables';

.attachment-item {
    padding: 0.75rem;
    height: 4.75rem;
    background-color: variables.$white;
    display: flex;
    justify-content: space-between;
    gap: 0.6rem;
    border: 1px solid variables.$dark-4;
    flex: 1;
    max-width: 16.25rem;
    align-items: center;
    margin-right: 0.5rem;

    &__icon {
    display: flex;
    align-items: center;
    height: 1.3125rem;
        &__suffix {
            justify-self: flex-end;
            cursor: pointer;
        }
    }

    &__infos {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 80%;
        overflow: hidden;

        &__name {
            color: variables.$main-text-color;
            font-size: .9375rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        
        &__size {
            color: variables.$dark-2;
            font-size: .75rem;
        }
    }
}

.attachment-error {
    color: variables.$error-color;
}

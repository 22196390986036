@use '../../utils/variables';

.address {
  &__container {
    input {
      padding-right: 4rem !important;
    }
  }

  &__dropdown {
    position: absolute;
    z-index: 2;
    background-color: variables.$white;
    width: 100%;
    border: 1px solid #dde5e9;
    border-top: 1px solid #fff;

    &-- {
      bottom: 99%;
    }
  }

  &__prefix {
    margin-right: 1rem;
  }

  &__item {
    padding: 0.5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: #f2f4f7;
    }
  }
}

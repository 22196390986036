@import '~react-toastify/scss/variables';

.toasted {
    @import '~react-toastify/scss/toastContainer';
    @import '~react-toastify/scss/toast';
    @import '~react-toastify/scss/theme';
    @import '~react-toastify/scss/closeButton';
    @import '~react-toastify/scss/progressBar';
    @import '~react-toastify/scss/icons';
    @import '~react-toastify/scss/animations/bounce.scss';
    @import '~react-toastify/scss/animations/zoom.scss';
    @import '~react-toastify/scss/animations/flip.scss';
    @import '~react-toastify/scss/animations/slide.scss';
    @import '~react-toastify/scss/animations/spin.scss';
    
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }
}

.Toastify__toast-body {
    font-size: 0.9rem;
    font-family:
        'EuclidCircularA-Regular',
        -apple-system,
        BlinkMacSystemFont,
        sans-serif;
}
@use '../utils/variables';

.passager-details-view {
  display: flex;
  flex-direction: column;

  .passager,
  .luggage {
    display: flex;
    gap: 24px;
  }
}

.passenger-credentials {
  &-civility {
    width: 6.4375rem !important;
    margin-right: 0.5rem;
  }

  &-lastname {
    width: calc(100% - 6.4375rem - 0.5rem);
  }

  .field {
    display: flex;
    vertical-align: middle;
    align-items: center;
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
    gap: 0.5rem;
    .passenger-phone-title {
      text-transform: uppercase;
      color: #001c40;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.08px;
      word-wrap: break-word;
      min-width: 6.4375rem;
      .title {
        .form-label {
          margin-bottom: 0!important;
        }
      }
    }
  }
}
@use '../utils/variables';

.passenger-form {
  background-color: white;
  width: 40rem;

  .passenger-item {
    padding: 0;

    &__delete {
      img {
        width: 26px;
      }
    }
  }

  .field {
    margin-top: 0.875rem;
    width: 100%;
  }

  .field-civility {
    width: 6.4375rem;
    margin-right: 0.5rem;
  }

  .field-lastname {
    width: calc(100% - 6.4375rem - 0.5rem);
  }

  .field h6 {
    color: variables.$dark-3;
    text-transform: uppercase;
    font-size: 1rem !important;
    margin-bottom: 0;
  }

  .footer-new-passenger {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0.938rem;
    margin-top: 3rem;
  }

  .title-info {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    color: variables.$main-bg-color;
  }

  &__divider {
    border-top: 1px solid variables.$main-bg-color;
    margin: 13px 0 13px 0;
  }

  &__address {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__content {
      display: flex;
    }

    &__item {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0.6rem;
      border: 1px solid variables.$light-1;
      background-color: variables.$light-4;
    }

    &__data {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
      flex: 1;

      &__name {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.7rem;

        .input-text {
          width: 100%;
        }
      }
      &__value {
        &__input {
          width: 100%;
          max-height: 12rem;
          overflow-y: auto;
        }
      }

      &__delete {
        width: 30px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0.6rem;
      }
    }

    &__add {
      display: flex;
      gap: 0.5rem;
      cursor: pointer;
    }

    &__add > .body-normal-small {
      color: variables.$main-text-color;
    }
  }
}

.component-dropdownpassenger-form__address__data__type {
  min-width: 9.8rem;
}

.react-select-containerpassenger-form__address__data__type > .react-select__control {
  padding: 1rem 0.5rem 1rem 0.5rem !important;
}

.passenger-form__address__data__value > .address__container > .address__dropdown {
  position: relative !important;
}

.header-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-delete-passenger {
  width: 45rem;
}
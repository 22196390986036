@use '../utils/variables';

.request-button-primary,
.request-button-secondary {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  height: 4rem;
  font-size: 100%;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
  border: none;
}

.request-button-primary {
  background-color: variables.$main-bg-color;
  color: variables.$white;
}

.request-button-secondary {
  border: solid;
  border-width: 1px;
  border-color: black;
  background-color: variables.$white;
  color: variables.$main-bg-color;
  font-family:
    'EuclidCircularA-Bold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  &--border-blue {
    border: 1px solid variables.$main-bg-color;
  }
}

.request-button-secondary:hover,
.request-button-primary:hover {
  background-color: variables.$lighter-bg-color;
}

.request-button-secondary:active,
.request-button-primary:active {
  background-color: variables.$secondary-bg-color;
}

.request-button-secondary:focus,
.request-button-primary:focus {
  border: solid;
  border-color: variables.$info-light;
  outline-style: none;
}

.content-button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.content-button > div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

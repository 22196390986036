@use '../../utils/variables';

.picker_time {
  position: relative;
  width: fit-content;

  &__placeholder {
    position: absolute;
    background-color: variables.$white;
    top: 30%;
    left: 10%;

    &--text {
      text-transform: uppercase;
      color: variables.$dark-2;
      font-family:
        'EuclidCircularA-Semibold',
        -apple-system,
        BlinkMacSystemFont,
        sans-serif;
    }
  }

  &__input {
    width: 10rem;
    font-family:
      'EuclidCircularA-Semibold',
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
    height: 3.438rem;
  }

  &__without_image {
    .picker_time{
      &__input {
        width: 5.5rem;
      }
      &__image {
        display: none;
      }
    }
  }

  &__image {
    background-color: variables.$white;
    position: absolute;
    right: 10%;
    top: 32%;
    padding: 0.5rem;
    pointer-events: none;
  }
}

@use '../utils/variables';

.article-form {
  display: flex;
  align-items: center;
  height: 45px;
  margin-bottom: 22px;

  &.reduce-margin {
    margin-bottom: 10px;
  }

  .article-header {
    display: flex;
    flex: 1;
    align-items: center;
    margin-bottom: 10px;

    .article-icon {
      width: 22.5px;
      margin-right: 20px;
    }

    .article-title {
      margin: 0;
      .title {
        text-transform: uppercase;
        color: #001c40;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.08px;
        word-wrap: break-word;
      }

      .title-with-icon {
        color: #8f90a6;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 21px;
        letter-spacing: 0.07px;

        img {
          margin-right: 10px;
        }
      }

      .sub-title {
        margin: 0;
        color: #385273;
        font-size: 14px;
        font-weight: 600;
        line-height: 23.8px;
      }
    }
  }

  .article-input {
    display: flex;
    align-items: center;
    flex: 1;

    .article-input-container {
      border-radius: 2px;
      border: 1px #e0e0e0 solid;

      input {
        width: 70px;
        text-align: center;
        border: none !important;
        outline: none;
        height: 40px;

        /* Firefox */
        -moz-appearance: textfield;

        /* Chrome */
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Opéra*/
        &::-o-inner-spin-button {
          -o-appearance: none;
          margin: 0;
        }
      }

      button {
        color: variables.$grey;
        background: none;
        border: none;
        cursor: pointer;
        margin: 0 5px;
      }
    }
  }
}

.article-view {
  margin-top: 10px;
  display: flex;
  height: 20px;
  font-family: 'EuclidCircularA-Semibold', -apple-system, BlinkMacSystemFont, sans-serif;
  gap: 4px;

  .value {
    color: variables.$main-text-color;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
  }

  .title {
    color: #8f90a6;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 21px;
    letter-spacing: 0.07px;
    word-wrap: break-word;
  }
}

@use '../utils/variables';

.popup {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.288) !important;

  &__modale {
    position: absolute;
    top: 10%;
    left: 35%;
    background-color: variables.$white;
    border-radius: 8px;
  }

  &__cross {
    position: absolute;
    z-index: 1;
    cursor: pointer;
    right: 6%;
    top: 7%;
  }
}

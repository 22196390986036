@use '../utils/variables';

.component-dropdown {
  display: flex;
  flex-direction: row;
}

.custom-dropdown {
  position: relative;
  width: 100%;
}

.select-img {
  position: absolute;
  top: 50%;
  left: 1rem;
  z-index: 1;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.react-select__input {
  border: inherit !important;
  background-color: inherit !important;
}

.react-select-container {
  display: flex;
  justify-content: flex-start;
  background-color: variables.$white;
  color: variables.$dark-2;
  width: 100%;
}

.react-select__placeholder {
  color: lime;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
}

.react-select__control {
  width: 100%;
  color: variables.$dark-2 !important;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  letter-spacing: 0.5px;
  height: 3.438rem;
  align-content: center !important;
  padding: 1rem 0.5rem 1rem 2.5rem;
  position: relative;
  border-color: variables.$light-1 !important;
  border-radius: 0 !important;
  outline: none;
  caret-color: transparent;
  box-shadow: none !important;

  &--menu-is-open {
    caret-color: transparent;

    .react-select__indicator {
      content: url('../../assets/img/icon/arrow-up.svg');
      background-repeat: no-repeat;
      background-position: right 0.6rem top 50%;
    }
  }
}

.react-select__single-value {
  color: variables.$dark-2 !important;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  letter-spacing: 0.5px;
}

.react-select__indicator {
  content: url('../../assets/img/icon/arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 0.6rem top 50%;

  &-separator {
    display: none;
  }
}

.react-select__menu {
  margin-top: 0 !important;
  margin-left: 1px !important;
  background-color: lime;

  &-list {
    padding: 0 0.6rem 0 0 !important;
    max-height: 22rem !important;

    &::-webkit-scrollbar {
      width: 0.5rem;
      height: 10rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      height: 15rem;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

// style  language
.react-select-container-lang .react-select__option {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  height: 3.6rem;
  background-color: variables.$white;
  color: variables.$main-bg-color !important;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  letter-spacing: 0.5px;
  border: none;

  &:hover {
    color: variables.$main-text-color;
  }

  &--is-selected {
    background-color: variables.$main-bg-color !important;
    color: white !important;
  }
}

.react-select-container-lang .react-select__control {
  &--menu-is-open {
    caret-color: transparent;

    width: 7rem;
    .react-dropdown-select-dropdown {
      overflow: hidden;
    }
    .react-select__indicators .react-select__indicator {
      content: url('../../assets/img/icon/language-arrow-up.svg');
      background-repeat: no-repeat;
      background-position: right 0.6rem top 50%;
    }
  }
  border: none;
  width: 7rem;
}

.react-select-container-lang .react-select__control .react-select__indicator {
  content: url('../../assets/img/icon/language-arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right 0.6rem top 50%;
}

.react-select-container-lang .react-select__menu {
  .react-dropdown-select-dropdown {
    overflow: hidden;
  }
}

.react-select-container-lang
  .react-select__control
  .react-select__value-container
  .react-select__single-value {
  color: variables.$main-bg-color !important;
}

.react-select-container-lang .react-select__control .react-select__menu {
  .react-dropdown-select-dropdown {
    overflow: hidden;
  }
  .react-dropdown-select-dropdown > div {
    max-height: none; /* ou une valeur spécifique à votre besoin */
  }
}

.react-select-container-lang .react-select__control--is-focused {
  width: 7rem;
}

.react-select-container-lang .react-select__menu .react-select__menu-list {
  padding: 0 !important;
}

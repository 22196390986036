@use '../../utils/variables';

.input-text {
  display: flex;
  position: relative;

  &__wide {
    width: 100% !important;
  }

  &__input {
    border: 2px solid variables.$white;
    border-left: 0;
    color: variables.$lighter-text-color;
    text-indent: 2rem;
    font-family:
      'EuclidCircularA-Semibold',
      -apple-system,
      BlinkMacSystemFont,
      sans-serif;
    font-size: 14px;

    &::placeholder {
      color: variables.$dark-3;
      text-indent: 2rem;
    }

    &--without-prefix,
    &--without-prefix::placeholder {
      text-indent: 0;
    }
  }

  &__prefix {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 0;
    width: 38px;
    position: absolute;
    top: 30%;
    left: 0.625rem;
  }

  &__suffix {
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 0;
    width: 38px;
    position: absolute;
    top: 30%;
    right: 0.625rem;

    &__clickable {
      cursor: pointer;
    }
  }

  &__cross {
    cursor: pointer;
    right: 4%;
    top: 1.156rem;
    color: variables.$white;
    position: absolute;
  }

  &__readonly {
    background-color: variables.$light-grey !important;
  }
}

@use '../utils/variables';

.page-global {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.page-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100vw - 3.5rem);
}

.page-component {
  flex: 1;
  overflow-y: auto;
  overflow-x: auto;
  background-color: variables.$light-grey;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  padding: 1.875rem 2.813rem 3.5rem 2.813rem;
}

.sidebar-background {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #001c4085;
}

.icon__clickable {
  cursor: pointer;
}

@use '../utils/variables';

@font-face {
  font-family: 'EuclidCircularA-Regular';
  src: url('../../assets/fonts/Euclid/Euclid\ Circular\ A\ Regular.ttf');
}

@font-face {
  font-family: 'EuclidCircularA-Semibold';
  src: url('../../assets/fonts/Euclid/Euclid\ Circular\ A\ Medium.ttf');
}

@font-face {
  font-family: 'EuclidCircularA-Bold';
  src: url('../../assets/fonts/Euclid/Euclid\ Circular\ A\ Bold.ttf');
}

.body-extra-small {
  font-size: 12px;
  font-family:
    'EuclidCircularA-Regular',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
}

.body-small {
  font-size: 14px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
}

.body-medium-small {
  font-size: 14px;
  font-family:
    'EuclidCircularA-Regular',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
}

.body-normal {
  font-size: 16px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
}

.body-normal-small {
  font-size: 16px;
  font-family:
    'EuclidCircularA-Regular',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
}

.body-medium {
  font-size: 18px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
}

.body-large {
  font-size: 20px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.7;
}

.lead-paragraph {
  font-size: 22px;
  font-family:
    'EuclidCircularA-Regular',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.5;
}

h6 {
  font-size: 18px !important;
  font-family:
    'EuclidCircularA-Regular',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.5;
  color: variables.$main-text-color;
}

h5 {
  font-size: 20px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.5;
  color: variables.$main-text-color;
  text-transform: uppercase;
}

h4 {
  font-size: 24px;
  font-family:
    'EuclidCircularA-Bold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.3;
  color: variables.$main-text-color;
}

h3 {
  font-size: 28px;
  font-family:
    'EuclidCircularA-Bold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.3;
  color: variables.$main-text-color;
}

h2 {
  font-size: 36px;
  font-family:
    'EuclidCircularA-Semibold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.3;
  color: variables.$main-text-color;
}

h1 {
  font-size: 44px;
  font-family:
    'EuclidCircularA-Bold',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.3;
  color: variables.$main-text-color;
}

.display-2 {
  font-size: 40px;
  font-family:
    'EuclidCircularA-Light',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.3;
}

.display-1 {
  font-size: 48px;
  font-family:
    'EuclidCircularA-Light',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  line-height: 1.3;
}
